/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  angular.module('comcast.directives').directive('fileSelect', ['RestrictFileService', function (RestrictFileService) {
    return {
      scope: {
        fileRetrieved: '=',
        fileErrors: '=',
        additionalData: '=' // Accept additional data

      },
      link: function link(scope, elem, attrs, ngModel) {
        var button = elem.find('button');
        var input = angular.element(elem[0].querySelector('input.hidden-file-input'));
        var display = angular.element(elem[0].querySelector('input.display-file-input')); // This attribute comes through as a string, so we need to convert it to a boolean for easier usage

        var multipleFiles = attrs.multipleFiles === 'true';
        var sizeLimit = attrs.maxSize ? parseInt(attrs.maxSize, 10) : 0;
        var fromSpotFileUpload = scope.additionalData && scope.additionalData.fromSpotFileUpload ? true : false;
        button.bind('click', function () {
          input[0].click();
        });
        input.bind('change', function (e) {
          if (multipleFiles) {
            scope.fileErrors = [];
            var fileList = [];
            var fileNames = '';

            for (var i = 0; i < e.target.files.length; i++) {
              if (sizeLimit && e.target.files[i].size > sizeLimit) {
                var sizeRank = 'bytes';
                var sizeName = sizeLimit;

                if (sizeLimit >= 1024) {
                  sizeName = (sizeLimit / 1024).toFixed(2);
                  sizeRank = 'Kb';
                }

                if (sizeLimit >= 1024 * 1024) {
                  sizeName = (sizeLimit / (1024 * 1024)).toFixed(2);
                  sizeRank = 'Mb';
                }

                if (sizeLimit >= 1024 * 1024 * 1024) {
                  sizeName = (sizeLimit / (1024 * 1024 * 1024)).toFixed(2);
                  sizeRank = 'Gb';
                }

                scope.fileErrors.push({
                  name: 'Size Error',
                  message: "File '" + e.target.files[i].name + "' was larger than the " + sizeName + ' ' + sizeRank + ' limit'
                });
              } else {
                fileList.push(e.target.files[i]);
              }

              if (fileList.length > 0) {
                if (i === 0) {
                  fileNames += fileList[fileList.length - 1].name;
                } else {
                  fileNames += ', ' + fileList[fileList.length - 1].name;
                }
              }
            }

            scope.$apply(function () {
              display.val(fileNames);
              scope.fileRetrieved = fileList;
            });
          } else if (RestrictFileService.checkRestrictedTypes(e.target.files[0].name, fromSpotFileUpload)) {
            scope.$apply(function () {
              var files = e.target.files;

              if (files[0]) {
                if (sizeLimit && files[0].size > sizeLimit) {
                  var _sizeRank = 'bytes';
                  var _sizeName = sizeLimit;

                  if (sizeLimit >= 1024) {
                    _sizeName = (sizeLimit / 1024).toFixed(2);
                    _sizeRank = 'Kb';
                  }

                  if (sizeLimit >= 1024 * 1024) {
                    _sizeName = (sizeLimit / (1024 * 1024)).toFixed(2);
                    _sizeRank = 'Mb';
                  }

                  if (sizeLimit >= 1024 * 1024 * 1024) {
                    _sizeName = (sizeLimit / (1024 * 1024 * 1024)).toFixed(2);
                    _sizeRank = 'Gb';
                  }

                  scope.fileErrors.push({
                    name: 'Size Error',
                    message: "File '" + files[0].name + "' was larger than the " + _sizeName + ' ' + _sizeRank + ' limit'
                  });
                } else {
                  scope.fileErrors = [];
                  var file = files[0];
                  display.val(file.name);
                  scope.fileRetrieved = file;
                  scope.$emit('spotFileUploadReady', file);
                }
              } else {
                display.val(null);
                scope.fileRetrieved = undefined;
              }
            });
          } else {
            display.val(null);
            scope.fileRetrieved = undefined;
            scope.$emit('invalidFileUploaded');
          }
        });
        scope.$watch(function () {
          if (multipleFiles) {
            return scope.fileRetrieved ? scope.fileRetrieved.length : 0;
          } else {
            return scope.fileRetrieved;
          }
        }, function (newVal, oldVal) {
          if (newVal === undefined || newVal === null || oldVal === 1) {
            display.val(null);
            input.val(null);
            return;
          }

          if (multipleFiles) {
            var fileList = [];
            var fileNames = '';

            for (var i = 0; i < scope.fileRetrieved.length; i++) {
              if (sizeLimit && scope.fileRetrieved[i].size > sizeLimit) {
                scope.fileErrors.push({
                  name: 'Size Error',
                  message: "File '" + scope.fileRetrieved[i].name + "' was larger than the 5Mb limit"
                });
                break;
              } else {
                fileList.push(scope.fileRetrieved[i]);
              }

              if (i === 0) {
                fileNames += fileList[fileList.length - 1].name;
              } else {
                fileNames += ', ' + fileList[fileList.length - 1].name;
              }
            }

            display.val(fileNames);
          }
        });
      }
    };
  }]);
})();
/* EXAMPLE USAGE
 <file-select class="md-block" layout="row" file-retrieved="vm.submission.spot" multiple-files='false'> <!--ng-model="vm.submission.spot"-->
	 <div class="detail-container" flex-order="1" layout="row" flex>
	 	<input id="fileInput" type="file" class="ng-hide hidden-file-input">
	 	<div class="container-border" flex-order="3"></div>
	 	<label class="input-label" flex-order="1">Spot</label>
	 	<input type="text" id="displayInput" class="input display-file-input" disabled flex-order="2">
	 </div>
	 <div flex-order="2" class="browse-button">
	 	<md-button id="uploadButton" class="detail-button md-secondary md-raised" tabindex=12>
	 		Browse
	 	</md-button>
	 </div>
 </file-select>
* */